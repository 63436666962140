import React from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { casinoTabs } from "../../lib/data/casinoTabs.data";

const GamesSearchModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState(false);
  const totalGames = {
    ...casinoTabs,
    ...casinoTabs.roulettetab,
    ...casinoTabs.baccarattab,
    ...casinoTabs.AndarBahartab,
    ...casinoTabs.TeenPattitab,
    ...casinoTabs.Blackjacktab,
    ...casinoTabs.thirtytwoCardtab,
    ...casinoTabs.sevenLuckytab,
    ...casinoTabs.sicbotab,
    ...casinoTabs.spribe,
    ...casinoTabs.Slots,
    ...casinoTabs.popularGames,
  };
  const allGames = Object.values(totalGames);

  const handleSearch = (e) => {
    if (e) {
      setSearchText(true);

      let filteredValue = Object.values(totalGames)?.filter((item) => {
        return (
          item.name && item.name.toLowerCase().indexOf(e?.toLowerCase()) > -1
        );
      });
      setFilteredData(filteredValue);
      console.log("Search Data", filteredValue);
    } else {
      setSearchText(false);
    }
  };
  console.log("FilterData", filteredData);

  return (
    <>
      <div className="searchGames" onClick={handleShow}>
        <BiSearch />
        <span>Search games</span>
      </div>

      <Modal
        size="md"
        show={show}
        onHide={handleClose}
        className="searchGamesModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Search your favourite games</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <InputGroup className="mb-3 search-icon">
            <Form.Control
              placeholder="Search"
              aria-label="Text input with dropdown button"
              type="search"
              onChange={(e) => handleSearch(e.target.value)}
              autoFocus
            />
          </InputGroup>
          <div className="GamesShow">
            {filteredData &&
              filteredData.map((item) => (
                <div className="displayGames">
                  <a href={item.redirectUrl}>
                    {item.imgUrl ? <img src={item.imgUrl} /> : item.img}
                    <span>{item.name}</span>
                  </a>
                </div>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GamesSearchModal;
