import React from "react";
import { useNavigate } from "react-router-dom";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import BackIcon from "../../../../assets/svg/BackIcon";

import TeenPatti3Card from "../../../../assets/images/card/home/Teenpatti3cards.jpg";
import BetonTeenPatti from "../../../../assets/images/card/home/Betonteenpatti.jpg";
import OneDayTeenPatti from "../../../../assets/images/card/home/Onedaypatti.jpg";
import TeenPattiSupernowa from "../../../../assets/images/card/home/ONDAY.gif";
import TeenPattiFaceOff from "../../../../assets/images/card/home/ONEDAY.gif";
import TeenPatti2020 from "../../../../assets/images/card/home/Teenpatti2020.gif";

const CradGames = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>

      <main className="main">
        <div className=" container">
          <div className="d-flex justify-content-between align-items-center mt-4">
            <h2 className="sectionTitle">Card Games</h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div className="providersection">
            <ul>
              <li>
                <a href="/casino/ezg-teen-patti-3-card">
                  <img src={TeenPatti3Card} alt="EzugiImg" />
                </a>
              </li>
              <li>
                <a href="/casino/ezg-bet-on-teen-patti">
                  <img src={BetonTeenPatti} alt="EzugiImg" />
                </a>
              </li>
              <li>
                <a href="/casino/ezg-one-day-teen-patti">
                  <img src={OneDayTeenPatti} alt="EzugiImg" />
                </a>
              </li>
              <li>
                <a href="/casino/sn-teen-patti">
                  <img src={TeenPattiSupernowa} alt="EzugiImg" />
                </a>
              </li>
              <li>
                <a href="/casino/sn-teen-patti-face-off">
                  <img src={TeenPattiFaceOff} alt="EzugiImg" />
                </a>
              </li>
              <li>
                <a href="/casino/sn-rng-teen-patti-2020">
                  <img src={TeenPatti2020} alt="EzugiImg" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default CradGames;
