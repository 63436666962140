import React from "react";
import { useNavigate } from "react-router-dom";

import BackIcon from "../../../../assets/svg/BackIcon";

import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";

import VivoImg from "../../../../assets/images/providers/Vivo.gif";
import PragmaticImg from "../../../../assets/images/providers/Pragmatic.gif";
import SupernowaImg from "../../../../assets/images/providers/Supernowa.jpg";
import WorldCasinoImg from "../../../../assets/images/providers/Worldcasino.jpg";
import EzugiImg from "../../../../assets/images/providers/EZUGInew.jpg";
import EvolutionImg from "../../../../assets/images/providers/Evolutionnew.jpg";

const ProviderPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <main className="main">
        <div className=" container">
          <div className="d-flex justify-content-between align-items-center mt-4">
            <h2 className="sectionTitle">Providers</h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div className="providersection">
            <ul>
              <li>
                <a href="/casino/ezugi">
                  <img src={EzugiImg} alt="EzugiImg" />
                </a>
              </li>
              <li>
                <a href="/casino/evolution">
                  <img src={EvolutionImg} alt="EzugiImg" />
                </a>
              </li>
              <li>
                <a href="/casino/vivo">
                  <img src={VivoImg} alt="EzugiImg" />
                </a>
              </li>
              <li>
                <a href="/casino/supernowa">
                  <img src={SupernowaImg} alt="EzugiImg" />
                </a>
              </li>
              <li>
                <a href="/casino/worldcasino">
                  <img src={WorldCasinoImg} alt="EzugiImg" />
                </a>
              </li>
              <li>
                <a href="/casino/pragmaticplay">
                  <img src={PragmaticImg} alt="EzugiImg" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>

      <BeforeLoginFooter />
    </>
  );
};

export default ProviderPage;
