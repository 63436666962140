import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Modal, Nav, Navbar, Form, Dropdown, Button } from "react-bootstrap";
import { Container, Row, Col, Offcanvas } from "react-bootstrap";
import MenuIcon from "../assets/svg/MenuIcon";
import MenuIcon1 from "../assets/images/icons/menuicon.png";
import ProfileIcon from "../assets/svg/ProfileIcon";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WalletIcon from "../assets/svg/WalletIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import BetIcon from "../assets/svg/BetIcon";
import SettingIcon from "../assets/svg/SettingIcon";
import StatementIcon from "../assets/svg/StatementIcon";
import LogoutIcon from "../assets/svg/LogoutIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import logo from "../assets/svg/logo_new.png";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import numberWithCommas from "../utils/numbersWithComma";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import { CasinoProviders } from "../lib/data";
// import Index from "../views/pages/privatePages/exchangepopup";
import { getExposureAmount } from "../redux/auth/actions";
import { getBetMatches, getUserBet } from "../redux/sports/actions";

import HomeIcon1 from "../assets/images/games-icon/home-icon1.png";
import EzugiIcon from "../assets/images/games-icon/Ezugi.png";
import EvolutionIcon from "../assets/images/games-icon/Evolution.png";
import CashierIcon1 from "../assets/images/games-icon/cashier.png";

import SportsIconNew from "../assets/images/games-icon/headicon/sports.svg";
import CashierIconNew from "../assets/images/games-icon/headicon/cashier.svg";
import EzugiIconNew from "../assets/images/games-icon/headicon/roulette.svg";
import EvolutionIconNew from "../assets/images/games-icon/headicon/table-games-new.svg";
import HomeIConNew from "../assets/images/games-icon/headicon/house.png";
import casinoround from "../assets/images/games-icon/headicon/spin_bg.png";
import casinogame from "../assets/images/games-icon/headicon/turntable.png";
import casinopoint from "../assets/images/games-icon/headicon/pointer.png";
import Bonus from "../assets/images/bonus.png";
import BotHeadIcon1 from "../assets/images/games-icon/play3d.png";
import BotHeadIcon2 from "../assets/images/games-icon/cricket-icon.png";
import BotHeadIcon3 from "../assets/images/games-icon/football.png";
import BotHeadIcon4 from "../assets/images/games-icon/plane.png";
import BotHeadIcon5 from "../assets/images/games-icon/dragon.png";
import BotHeadIcon6 from "../assets/images/games-icon/cards.png";
import BotHeadIcon7 from "../assets/images/games-icon/fortune-wheel.png";
import Referral from "../assets/images/refferal.png";
import Bonus1 from "../assets/images/bonus.png";

import TextIcon from "../assets/images/icons/text-size.png";
import WhatsappIcon1 from "../assets/images/icons/whatsapp.png";
import BonusIcon from "../assets/images/icons/Bonus.png";
import SportsIcon1 from "../assets/images/icons/Sports-white.png";
import SearchIcon from "../assets/images/icons/find.png";
import WalletIcon1 from "../assets/images/icons/wallet.png";

import BonusIcon1 from "../assets/images/icons/Bonus.png";
import AccountStatement1 from "../assets/images/icons/Account-setting.png";
import ReferralIcon1 from "../assets/images/icons/withdraw.png";
import MyBets1 from "../assets/images/icons/Account-statement.png";
import SignOut1 from "../assets/images/icons/signout.png";
import ProfileIcon1 from "../assets/images/icons/Profile.png";
import { handleRightSideBar } from "../redux/app/actions";

import FetchingBal from "../assets/images/icons/fetch.gif";
import refferal from "../assets/images/icons/referral.png";
import { AiOutlineClose } from "react-icons/ai";
import StarIcon from "../assets/images/icons/Gold-Shine-PNG-Image.png";
import AppDownload from "../assets/images/icons/download.png";

import EzugiIconew from "../assets/images/icons/ezugiivon.png";
import SlotsIconnew from "../assets/images/icons/jackpot.png";
import EvolutionIconew from "../assets/images/icons/cards.png";
import AviatorICon from "../assets/images/icons/aeroplane.png";
import Supernvowa from "../assets/images/icons/supernowaicon.png";
import SpribeIcon from "../assets/images/icons/airplane-accident.png";
import Dice from "../assets/images/icons/dice.png";
import GamesSearchModal from "../views/components/GamesSearchModal";
import Contactnum1 from "../assets/images/icons/customer-service.png";

const HeaderAfterLogin = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const isBonus = numberWithCommas(wallet?.coins) || 0;
  const { betMatches, userBets } = useSelector((state) => state.sports);
  const { showRightSideBar } = useSelector((state) => state.app);
  const [showBalance, setShowBalanceLoading] = useState(false);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    handleSideBarVisibility(false);
    setShow(false);
  };
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showexchangeModal, setShowexchangeModal] = useState(false);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const [visible, setVisible] = useState(true);
  const [fontConfig, setFontConfig] = useState({
    counter: 0,
    fontSize: 12,
    type: "inc",
  });
  const isApk = localStorage.getItem("isApk") === "true";
  const [showToggle, setShowToggle] = useState(true);

  useEffect(() => {
    if (visible) {
      document.body.classList.add("welcome_note");
    } else {
      document.body.classList.remove("welcome_note");
    }
  }, [visible]);

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = [
    "betby",
    "casino/ezugi",
    "provider",
    "casino/evolution",
    "casino/spribe/aviator",
    "Spribe",
    "casino/vivo",
    "qtechlobby",
    "/betby?bt-path=%2Flive",
    "/exchange/Inplay",
    "/exchange/Cricket",
    "/spmsports/cricket",
  ];
  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);
  useEffect(() => {
    // dispatch(getExposureAmount());
    // dispatch(getBetMatches({ status: "pending" }));
    setShowToggle(window.screen.width > 992);
    let intervalID = setInterval(() => {
      setShowBalanceLoading((p) => !p);
    }, 5000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  const [showLightModeToggle, setShowLightModeToggle] = useState(false);
  useEffect(() => {
    if (showLightModeToggle) {
      document.body.classList.add("light-mode");
    } else {
      document.body.classList.remove("light-mode");
    }
  }, [showLightModeToggle]);

  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  const htmlElement = document.documentElement;
  useEffect(() => {
    if (showLeftbarToggle) {
      htmlElement.style.overflow = "hidden";
      document.body.classList.add("leftbat-opened");
    } else {
      htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);

  const handleSideBarVisibility = (value) => {
    dispatch(handleRightSideBar(value));
  };
  const handleFontConfig = () => {
    if (fontConfig.counter <= 2 && fontConfig.type == "inc") {
      if (fontConfig.counter === 2) {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter - 1,
          fontSize: p.fontSize - 2,
          type: "dec",
        }));
      } else {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter + 1,
          fontSize: p.fontSize + 2,
        }));
      }
    } else if (fontConfig.counter >= 0 && fontConfig.type == "dec") {
      if (fontConfig.counter === 0) {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter + 1,
          fontSize: p.fontSize + 2,
          type: "inc",
        }));
      } else {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter - 1,
          fontSize: p.fontSize - 2,
        }));
      }
    }
  };

  const renderBalance = () => {
    return (
      <>
        <div className="cashloader">
          <div className="balance-web">
            <ul>
              <li>
                Cash: <span>{numberWithCommas(wallet?.cash || 0)}</span>
              </li>
              {isBonus != 0 && (
                <li>
                  Coins:
                  <span> {numberWithCommas(wallet?.coins)}</span>
                  {/* <span> {numberWithCommas(exposureAmount || 0)}</span> */}
                </li>
              )}
              {/* <li>
                Sports: <span> {numberWithCommas(exposureAmount || 0)}</span>
              </li> */}
            </ul>
          </div>
          {/* <p>
            //{" "}
            <span>
              // <img src={FetchingBal} />
              // Fetching Balance... //{" "}
            </span>
            //{" "}
          </p> */}
        </div>
      </>
    );
  };

  return (
    <>
      {visible ? (
        <div className="marqu">
          <marquee>
            <img src={StarIcon} alt="star icon" />
            Welcome to Ramdevbook.
            <img src={StarIcon} alt="star icon" />
          </marquee>
          <span onClick={() => setVisible(!visible)}>
            <AiOutlineClose />
          </span>
        </div>
      ) : (
        ""
      )}
      <header className="header aftrlgn">
        <div className="top_head">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center afterloginsection">
                    <div className="leftbar_toggle d-md-none d-block ">
                      <Button
                        variant="primary"
                        onClick={() => setShowLeftbarToggle((s) => !s)}
                      >
                        {/* <MenuIcon /> */}
                        <img src={MenuIcon1} />
                      </Button>
                    </div>
                    <div className="headerRight">
                      {(!isApk && (
                        <a
                          href="javascript:void(0)"
                          // download=""
                          className="headicons support"
                        >
                          <img src={AppDownload} /> App
                        </a>
                      )) ||
                        null}

                      <a href="/wallet" className="headicons depositbtn">
                        <img src={WalletIcon1} /> Deposit
                      </a>
                      <a
                        className="panel headicons d-block d-md-none"
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `${appDetails?.WHATS_APP_URL}`
                            : "javascript:void(0)"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        <img src={WhatsappIcon1} />
                      </a>
                    </div>
                  </div>

                  <div className="logo">
                    <a href="/home">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>

                  <Navbar expand="xl" className="d-none ">
                    <Navbar.Toggle
                      aria-controls="basic-navbar-nav"
                      onClick={() => setShowToggle((s) => !s)}
                    />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav>
                        <Nav.Link href="/home" className="logoImg d-xl-none">
                          <img src={appDetails?.LOGO_URL} alt="Logo" />
                        </Nav.Link>
                        <Nav.Link href="/home">
                          {/* <HomeIcon />  */}
                          <img src={HomeIConNew} />
                          Home
                        </Nav.Link>
                        <Nav.Link href="/sportsbook/cricket" className="">
                          <div>
                            <img src={SportsIconNew} />
                          </div>
                          Exchange
                        </Nav.Link>
                        <Nav.Link href="/casino/spribe/aviator">
                          {/* <GamesIcon /> */}
                          <div className="animate-img">
                            <div className="enter-animate active">
                              <div className="spin-anim">
                                <img src={casinoround} className="img-spinbg" />
                                <img
                                  src={casinogame}
                                  className="img-turntable"
                                />
                                <img
                                  src={casinopoint}
                                  className="img-pointer"
                                />
                              </div>
                            </div>
                          </div>
                          Casino
                        </Nav.Link>

                        <Nav.Link href={CasinoProviders["ezugi"]?.href}>
                          {/* <GamesIcon />  */}
                          <img src={EzugiIconNew} />
                          Ezugi
                        </Nav.Link>
                        <Nav.Link href={CasinoProviders["evolution"]?.href}>
                          {/* <GamesIcon />  */}
                          <img src={EvolutionIconNew} />
                          Evolution
                        </Nav.Link>

                        <Nav.Link href="/cashier">
                          {/* <CashierIcon /> */}
                          <img src={CashierIconNew} />
                          Cashier
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>

                  <div className="headerRight headerrightsec">
                    <GamesSearchModal />

                    <a
                      className="panel headicons d-none d-md-block"
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `${appDetails?.WHATS_APP_URL}`
                          : "javascript:void(0)"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      <img src={WhatsappIcon1} />
                    </a>

                    <ul className="d-flex d-md-none mx-2 mob-whatsapp">
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `${appDetails?.WHATS_APP_URL}`
                              : "javascript:void(0)"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          <img src={WhatsappIcon1} />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src={SearchIcon} />
                        </a>
                      </li>
                    </ul>
                    {renderBalance()}
                    <Dropdown align="end" className="me-2 d-none ">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="tel:{user?.mobileno}">
                          <b>{user?.mobileno}</b>
                        </Dropdown.Item>
                        <Dropdown.Item href="javascript:void(0)">
                          <h6>Balance</h6>
                          <span>INR {numberWithCommas(wallet?.cash || 0)}</span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="javascript:void(0)"
                          onClick={() => setShowBetModal(true)}
                        >
                          <h6>Exposure</h6>
                          <span>
                            INR {numberWithCommas(exposureAmount || 0)}
                          </span>
                        </Dropdown.Item>
                        {isBonus != 0 && (
                          <Dropdown.Item href="javascript:void(0)">
                            <h6>Coins</h6>
                            <span>INR {numberWithCommas(wallet?.coins)}</span>
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item href="/gatewaylist" className="deposit">
                          Deposit
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <div className="funds">
                      <ul>
                        <li className="d-none">
                          <a href="/gatewaylist" className="deposit">
                            Deposit
                          </a>
                        </li>
                        <Dropdown
                          className="headerMainDropdown d-block d-md-none"
                          placement={"end"}
                        >
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="deposit"
                          >
                            Deposit
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="">
                              <div className="row">
                                <div className="col-6">
                                  <div className="balance mb-1">
                                    Main Balance
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="balance-amount mb-1">
                                    {numberWithCommas(wallet?.cash || 0)}
                                  </div>
                                </div>
                              </div>
                            </Dropdown.Item>
                            {isBonus != 0 && (
                              <Dropdown.Item href="">
                                <div className="row">
                                  <div className="col-6">
                                    <div className="balance ">Bonus</div>
                                  </div>
                                  <div className="col-6">
                                    <div className="balance-amount">
                                      {numberWithCommas(wallet?.coins) || 0}
                                    </div>
                                  </div>
                                </div>
                              </Dropdown.Item>
                            )}
                            <Dropdown.Item href="">
                              <div
                                className="col-12 "
                                onClick={() => {
                                  navigate("/withdraw");
                                }}
                              >
                                <Button variant="  w-100" className="primary">
                                  Withdraw
                                </Button>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item href="">
                              <div
                                className="col-12"
                                onClick={() => {
                                  navigate("/gatewaylist");
                                }}
                              >
                                <Button variant="primary w-100">Deposit</Button>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </ul>
                    </div>
                    <Button
                      variant="primary menu-btn "
                      className="d-none d-md-block"
                      onClick={() => handleSideBarVisibility(true)}
                    >
                      <ProfileIcon />
                      {/* <MenuIcon /> */}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Offcanvas
          show={showRightSideBar}
          onHide={handleClose}
          placement={"end"}
          className="menuDropdown"
        >
          <Offcanvas.Header closeButton="closeButton" closeVariant="black">
            <Offcanvas.Title>
              <div className="d-flex">
                <div className="profile-img">
                  <ProfileIcon />
                </div>
                <div className="user-info">
                  <span>{user?.mstruserid}</span>
                  <a href="javascript:void(0)">{user?.mobileno}</a>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="row offcanvas-head">
              <div className="col-6">
                <div className="balance mb-1">Main Balance</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-1">
                  {numberWithCommas(wallet?.cash || 0)}
                </div>
              </div>
              {isBonus != 0 && (
                <>
                  <div className="col-6">
                    <div className="balance mb-3">Coins</div>
                  </div>
                  <div className="col-6">
                    <div className="balance-amount mb-3">
                      {numberWithCommas(wallet?.coins) || 0}
                    </div>
                  </div>
                </>
              )}
              <div className="col-6 ">
                <a href="/withdraw" className="btn btn-secondary w-100">
                  Withdraw
                </a>
              </div>
              <div className="col-6">
                <a href="/gatewaylist" className="btn btn-primary w-100">
                  Deposit
                </a>
              </div>
              {/* <div className="col-4">
              <Button variant="primary w-100" onClick={handleShowModal}>
                Exchange
              </Button>
              <ExchangeFundsFormPopUp
                showexchangeModal={showexchangeModal}
                handleCloseModal={handleCloseModal}
              />
            </div> */}
            </div>
            <ul className="menu-items">
              <li>
                <a href="/profile">
                  <img src={ProfileIcon1} />
                  {/* <ProfileIcon /> */}
                  <span>Profile</span>
                </a>
              </li>
              <li>
                <a href="/mybets">
                  {/* <BetIcon /> */}
                  <img src={MyBets1} />
                  <span>Casino Bets</span>
                </a>
              </li>
              <li>
                <a href="/referral">
                  {/* <BetIcon /> */}
                  <img src={refferal} />
                  <span>Referral</span>
                </a>
              </li>

              {/* <li>
              <a href="/bonus">
                <img src={Bonus1} />
                <img src={BonusIcon1} />
                <span>Bonus</span>
              </a>
            </li>
            <li>
              <a href="/referral">
                <img src={Referral} />
                <img src={ReferralIcon1} />
                <span>Referral</span>
              </a>
            </li> */}
              <li>
                <a href="/cashier">
                  {/* <StatementIcon /> */}
                  <img src={AccountStatement1} />
                  <span>Account Statement</span>
                </a>
              </li>

              {/* <li>
              <a href="#">
                <SettingIcon />
                <span>Transfer Funds ( For Excahnge)</span>
              </a>
            </li> */}
              {/* <li>
              <a href="/settings">
                <SettingIcon />
                <span>Settings</span>
              </a>
            </li> */}
              <li>
                <a href="/bonus" className="">
                  <img src={BonusIcon} /> Bonus
                </a>
              </li>
              <li
                onClick={() => {
                  dispatch(logoutUser());
                }}
              >
                <a href="/">
                  {/* <LogoutIcon /> */}
                  <img src={SignOut1} />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
            <ul className="social_links">
              <li>
                <a
                  href={
                    appDetails?.TELEGRAM != ""
                      ? appDetails?.TELEGRAM
                      : "javascript:void(0)"
                  }
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <TelegramIcon />
                </a>
              </li>
              {/* <li>
              <div
                onClick={() =>
                  window.open(https://wa.me/+917888846060, "_blank")
                }
              >
                <WhatsappIcon />
              </div>
            </li> */}
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `${appDetails?.WHATS_APP_URL}`
                      : "javascript:void(0)"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <WhatsappIcon />
                </a>
              </li>
              <li className="theme-btn">
                <buton
                  type="button"
                  onClick={() => setShowLightModeToggle((s) => !s)}
                >
                  <div className="theme-icon">
                    <img
                      src={MoonLight}
                      alt="Moon Icon"
                      className="dark_theme"
                    />
                    <img
                      src={SunLight}
                      alt="Sun Icon"
                      className="light_theme"
                    />
                  </div>
                </buton>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          className="betslip_popup outer_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Exposure amount</th>
                      <th scope="col">Match name</th>
                      <th scope="col">Ref match ID</th>
                      <th scope="col">Total bets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betMatches &&
                      betMatches.length &&
                      betMatches.map((item, index) => {
                        return (
                          <tr
                            onClick={() => {
                              handleRowClick(item);
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{item?.exposureAmount}</td>
                            <td>{item?.matchName}</td>
                            <td>{item?.refMatchId}</td>
                            <td>{item?.totalBets}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="betslip_popup inner_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showUserBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Odds</th>
                      <th scope="col">Stack</th>
                      <th scope="col">Selection type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Create date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userBets &&
                      userBets.length &&
                      userBets.map((item, index) => {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.odds}</td>
                            <td>{item?.stack}</td>
                            <td>{item?.selectionType}</td>
                            <td>{item?.status}</td>
                            <td>{item?.createdAt}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <div className="bottom_head">
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <div className="d-flex ContactSupportSection">
                  <div className="ContactSupport">
                    <p>Contact for Support</p>
                    <a href="tel:{user?.mobileno}">
                      <img src={Contactnum1} alt="Contact1" />
                      <span>+91 74130 68871</span>
                      <img
                        src={Contactnum1}
                        alt="Contact1"
                        className="LastImg"
                      />
                      <span>+91 76899 79212</span>
                    </a>
                  </div>
                  <ul style={{ fontSize: `${fontConfig.fontSize}px` }}>
                    <li>
                      <a
                        href="/spmsports/cricket"
                        className={
                          activePage == "/spmsports/cricket" ? "active" : ""
                        }
                      >
                        <img
                          src={BotHeadIcon1}
                          alt="bottom head link icon"
                          className="inplay"
                        />
                        <span>Inplay</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/betby"
                        className={activePage == "betby" ? "active" : ""}
                      >
                        <img src={AviatorICon} alt="bottom head link icon" />
                        <span>Sportsbook</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/provider"
                        className={activePage == "provider" ? "active" : ""}
                      >
                        <img src={Supernvowa} alt="bottom head link icon" />
                        <span>Casino</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/spmsports/cricket"
                        className={
                          activePage == "/spmsports/cricket" ? "active" : ""
                        }
                      >
                        <img
                          src={BotHeadIcon1}
                          alt="bottom head link icon"
                          className="inplay"
                        />
                        <span>Exchange</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
};

export default HeaderAfterLogin;
