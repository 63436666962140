import React from "react";

import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";

const index = () => {
  return (
    <div>
      <div>
        <Header />
      </div>
      <main className="BeforeExchange">
        <iframe src="https://design.powerplay247.com/auth"></iframe>
      </main>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
};

export default index;
