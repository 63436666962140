import React from "react";
import Header from "../../../../containers/Header";

const BeforeExchange = () => {
  return (
    <div>
      <Header />
      <iframe
        src="https://design.powerplay247.com/auth"
        style={{ width: "100%", height: "100vh" }}
      />
    </div>
  );
};

export default BeforeExchange;
