import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Container, Row, Col, Nav, Navbar, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import CashierIcon from "../assets/svg/CashierIcon";
import logo from "../assets/svg/logo_new.png";
import BotHeadIcon1 from "../assets/images/games-icon/play3d.png";
import BotHeadIcon2 from "../assets/images/games-icon/cricket-icon.png";
import BotHeadIcon3 from "../assets/images/games-icon/football.png";
import BotHeadIcon4 from "../assets/images/games-icon/plane.png";
import BotHeadIcon5 from "../assets/images/games-icon/dragon.png";
import BotHeadIcon6 from "../assets/images/games-icon/cards.png";
import BotHeadIcon7 from "../assets/images/games-icon/fortune-wheel.png";
import { useSelector } from "react-redux";

import SportsIconNew from "../assets/images/games-icon/headicon/sports.svg";
import CashierIconNew from "../assets/images/games-icon/headicon/cashier.svg";
import EzugiIconNew from "../assets/images/games-icon/headicon/roulette.svg";
import EvolutionIconNew from "../assets/images/games-icon/headicon/table-games-new.svg";
import HomeIConNew from "../assets/images/games-icon/headicon/house.png";
import casinoround from "../assets/images/games-icon/headicon/spin_bg.png";
import casinogame from "../assets/images/games-icon/headicon/turntable.png";
import casinopoint from "../assets/images/games-icon/headicon/pointer.png";

import WhatsappIconNew from "../assets/images/social/whatsapp.png";
import TelegramIconNew from "../assets/images/social/telegram.png";

import MenuIcon from "../assets/images/icons/menu1.png";
import CasinoIcon from "../assets/images/icons/casino-white.png";
import SportsIcon1 from "../assets/images/icons/Sports-white.png";
import TextIcon from "../assets/images/icons/text-size.png";
import WhatsappIcon1 from "../assets/images/icons/whatsapp.png";
import BonusIcon from "../assets/images/icons/Bonus.png";
import LogInIcon from "../assets/images/icons/login-white.png";
import SupportIcon from "../assets/images/icons/Support-white.png";

import SearchIcon from "../assets/images/icons/find.png";
import { AiOutlineClose } from "react-icons/ai";
import StarIcon from "../assets/images/icons/Gold-Shine-PNG-Image.png";
import Promotionicon from "../assets/images/icons/Promotion.png";
import AppDownload from "../assets/images/icons/download.png";

import EzugiIconew from "../assets/images/icons/ezugiivon.png";
import SlotsIconnew from "../assets/images/icons/jackpot.png";
import EvolutionIconew from "../assets/images/icons/cards.png";
import AviatorICon from "../assets/images/icons/aeroplane.png";
import Supernvowa from "../assets/images/icons/supernowaicon.png";
import SpribeIcon from "../assets/images/icons/airplane-accident.png";
import Dice from "../assets/images/icons/dice.png";
import GamesSearchModal from "../views/components/GamesSearchModal";

import Contactnum1 from "../assets/images/icons/customer-service.png";

const Header = () => {
  //test
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const isApk = localStorage.getItem("isApk") === "true";
  const { appDetails } = useSelector((state) => state.app);
  const [visible, setVisible] = useState(true);
  const [fontConfig, setFontConfig] = useState({
    counter: 0,
    fontSize: 14,
    type: "inc",
  });

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = ["betby", "sports-exchange", "beforeprovider"];
  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const handleFontConfig = () => {
    if (fontConfig.counter <= 2 && fontConfig.type == "inc") {
      if (fontConfig.counter === 2) {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter - 1,
          fontSize: p.fontSize - 2,
          type: "dec",
        }));
      } else {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter + 1,
          fontSize: p.fontSize + 2,
        }));
      }
    } else if (fontConfig.counter >= 0 && fontConfig.type == "dec") {
      if (fontConfig.counter === 0) {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter + 1,
          fontSize: p.fontSize + 2,
          type: "inc",
        }));
      } else {
        setFontConfig((p) => ({
          ...p,
          counter: p.counter - 1,
          fontSize: p.fontSize - 2,
        }));
      }
    }
  };

  return (
    <>
      {visible ? (
        <div className="marqu">
          <marquee>
            <img src={StarIcon} alt="star icon" />
            Welcome to Ramdevbook.
            <img src={StarIcon} alt="star icon" />
          </marquee>
          <span onClick={() => setVisible(!visible)}>
            <AiOutlineClose />
          </span>
        </div>
      ) : (
        ""
      )}
      <header className="header beforeheader">
        <div className="top_head">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="headerRight appnone">
                    {(!isApk && (
                      <a
                        href="javascript:void(0)"
                        download=""
                        className="headicons support"
                      >
                        <img src={AppDownload} /> App
                      </a>
                    )) ||
                      null}
                  </div>

                  <div className="logo">
                    <a href="/">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>

                  <div className="headerRight headerrightsec">
                    <GamesSearchModal />

                    {/* <a href="/sign-in" className="headicons">
                      <img src={BonusIcon} /> Bonus
                    </a> */}
                    <a
                      className="panel headicons"
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `${appDetails?.WHATS_APP_URL}`
                          : "javascript:void(0)"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      <img src={WhatsappIcon1} />
                    </a>
                    <ul className="social d-none">
                      <li>
                        <a
                          href={
                            appDetails?.TELEGRAM != ""
                              ? appDetails?.TELEGRAM
                              : "javascript:void(0)"
                          }
                          target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                        >
                          {/* <TelegramIcon /> */}
                          <img src={TelegramIconNew} />
                        </a>
                      </li>
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `${appDetails?.WHATS_APP_URL}`
                              : "javascript:void(0)"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          {/* <WhatsappIcon /> */}
                          <img src={WhatsappIconNew} />
                        </a>
                      </li>
                    </ul>
                    <ul className="d-flex d-md-none mx-2">
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `${appDetails?.WHATS_APP_URL}`
                              : "javascript:void(0)"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          <img src={WhatsappIcon1} />
                        </a>
                      </li>
                      {/* <li>
                        <a href="#">
                          <img src={SearchIcon} />
                        </a>
                      </li> */}
                    </ul>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/sign-in");
                      }}
                      className="button-primary btn_signin"
                    >
                      Login
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/mobile-number");
                      }}
                      className="button-primary btn_signup btn-secondary ms-2"
                    >
                      Sign up
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="bottom_head">
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <div className="d-flex ContactSupportSection">
                  <div className="ContactSupport">
                    <p>Contact for Support</p>
                    <a href="tel:{user?.mobileno}">
                      <img src={Contactnum1} alt="Contact1" />
                      <span>+91 74130 68871</span>
                      <img
                        src={Contactnum1}
                        alt="Contact1"
                        className="LastImg"
                      />
                      <span>+91 76899 79212</span>
                    </a>
                  </div>
                  <ul style={{ fontSize: `${fontConfig.fontSize}px` }}>
                    <li>
                      <a
                        href="/sign-in"
                        className={activePage == "" ? "active" : ""}
                      >
                        <img
                          src={BotHeadIcon1}
                          alt="bottom head link icon"
                          className="inplay"
                        />
                        <span>Inplay</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/betby"
                        className={activePage == "betby" ? "active" : ""}
                      >
                        <img
                          src={EzugiIconew}
                          alt="bottom head link icon"
                          className=""
                        />
                        <span>Sportsbook</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/beforeprovider"
                        className={
                          activePage == "beforeprovider" ? "active" : ""
                        }
                      >
                        <img
                          src={SlotsIconnew}
                          alt="bottom head link icon"
                          className=""
                        />
                        <span>Casino</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/sign-in"
                        className={activePage == "sign-in" ? "active" : ""}
                      >
                        <img
                          src={SlotsIconnew}
                          alt="bottom head link icon"
                          className=""
                        />
                        <span>Exchange</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                 Login with OTP
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
      </header>
    </>
  );
};

export default Header;
